<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="outlet.orders"
          :search="search"
        >
          <!-- start of posting date template -->
          <template v-slot:item.DocDate="{ item }">{{ item.DocDate | moment("Do MMM YYYY")}}</template>
          <!-- end of posting date template -->

          <!-- start of delivery date template -->
          <template v-slot:item.DocDueDate="{ item }">{{ item.DocDueDate | moment("Do MMM YYYY")}}</template>
          <!-- end of delivery date template -->

          <!-- start of document date template -->
          <template v-slot:item.TaxDate="{ item }">{{ item.TaxDate | moment("Do MMM YYYY")}}</template>
          <!-- end of document date template -->

          <!-- start of vat sum template -->
          <template v-slot:item.VatSum="{ item }">{{ item.VatSum}}</template>
          <!-- end of vat sum template -->

          <!-- start of doc total template -->
          <template v-slot:item.DocTotal="{ item }">{{ item.DocTotal.toLocaleString()}}</template>
          <!-- end of doc total template -->

          <!-- action template -->
          <!-- <template v-slot:item.action="{ item }">
            <v-btn icon :to="`/sales/doc/${activeObject}/${item.id}`">
              <v-icon color="green">mdi-eye</v-icon>
            </v-btn>
          </template>-->
          <!-- end of action template -->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      outlet: {},
      loading: false,
      id: null,
      headers: [
        { text: "Doc Number", value: "DocNum" },
        { text: "Customer Name", value: "CardName" },
        { text: "Posting Date", value: "DocDate" },
        { text: "Due Date", value: "DocDueDate" },
        { text: "Document Date", value: "TaxDate" },
        { text: "VAT", value: "VatSum" },
        { text: "Document Total", value: "DocTotal" },
        // { text: "View More", value: "action" },
      ],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true,
    },
  },
  methods: {
    getData(val) {
      const self = this;
      self.id = val;
      this.loading = true;
      this.$store
        .dispatch("get", `/v1/outlet/${val}`)
        .then((res) => {
          self.outlet = res;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>